import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "toggleable", "newable", "init", "wait", "init2", "wait2",
      "initcomplete", "waitcomplete", "initskip", "waitskip",
      "completing", "skipping", "stepactions" ]
  }

  connect() {
    console.log('ux_controller.js')
  }

  reset() {
    console.log('reset')
    this.element.reset()
  }

  hide(event) {
    console.log(event)
    event.target.classList.add("hidden")
  }

  waiting() {
    var x = document.getElementById("waiting-modal")
    x.classList.remove("hidden")
  }

  wait() {
    this.waitTarget.classList.remove('hidden')
    this.initTarget.classList.add('hidden')
  }

  wait2() {
    this.wait2Target.classList.remove('hidden')
    this.init2Target.classList.add('hidden')
  }

  waitComplete() {
    this.stepactionsTarget.classList.add('hidden')
    this.completingTarget.classList.remove('hidden')
  }

  waitSkip() {
    this.stepactionsTarget.classList.add('hidden')
    this.skippingTarget.classList.remove('hidden')
  }

  toggle() {
    this.toggleableTarget.classList.toggle('hidden')
  }

  toggleNew() {
    this.newableTarget.classList.toggle('hidden')
  }

  toggleClass() {
    this.toggleableTarget.classList.toggle('toggle-class')
  }

  toggles() {
    var i;
    var x = this.toggleableTargets
    for (i = 0; i < x.length; i++) { x[i].classList.toggle("hidden"); }
  }
}
