// app/assets/javascripts/controllers/search_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return [ "term", "output", "form", "button", "toggleable" ] }
  search(e) { this.buttonTarget.click() }
  reset() {
    this.formTarget.reset()
    this.toggleableTarget.classList.toggle('hidden')
  }
}
